* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

}

html {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: aliceblue;
}

span {
    font-size: 12px;
    color: #BE6FFC;
}

h1, h2, h3 {

}

.App {
    background-color: black;
}

@media screen and (max-width: 940px) {
    html {
        font-size: 14px;
    }
}
