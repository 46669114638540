.container {
    min-height: 100vh;
    max-width: 1280px;
    padding: 0 10px;
    margin: 0 auto;
}

.section__download h2 {
    margin-bottom: 30px;
    text-align: center;
}

.section__download {
}

.content {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.new__building, .other {
    max-width: 400px;
    padding: 50px 20px;
    margin-bottom: 30px;
    border: 1px solid rgba(251, 75, 2, 1);
}


.title {
    font-size: 42px;
    margin-bottom: 50px;
    text-align: center;
}

.wrapper {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
}

.label {
    display: block;
    margin-bottom: 10px;
}

.input__wrapper {
    justify-self: center;

}

.text__wrapper {

}

.text__output {
}

.input {
    height: 30px;
    width: 300px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #BE6FFC;
    outline: none;
    margin-bottom: 20px;
}

input:focus::placeholder, input:active::placeholder {
    color: transparent;
}

.buttons {
    display: flex;
    justify-content: space-evenly;
}

.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0 rgba(255, 255, 255, .5),
    7px 7px 20px 0 rgba(0, 0, 0, .1),
    4px 4px 5px 0 rgba(0, 0, 0, .1);
    outline: none;
}

.instruction {
    max-width: 400px;
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 151, 0, 1);
}

.btn-7 {
    background: linear-gradient(0deg, rgba(255, 151, 0, 1) 0%, rgba(251, 75, 2, 1) 100%);
    line-height: 42px;
    padding: 0;
    border: none;
}

.btn-7 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: white;
}

.btn-7:before,
.btn-7:after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    background: rgba(251, 75, 2, 1);
    box-shadow: -7px -7px 20px 0 rgba(255, 255, 255, .9),
    -4px -4px 5px 0 rgba(255, 255, 255, .9),
    7px 7px 20px 0 rgba(0, 0, 0, .2),
    4px 4px 5px 0 rgba(0, 0, 0, .3);
    transition: all 0.3s ease;
}

.btn-7:before {
    height: 0;
    width: 2px;
}

.btn-7:after {
    width: 0;
    height: 2px;
}

/*.btn-7:hover {*/
/*    color: rgba(251, 75, 2, 1);*/
/*    background: transparent;*/
/*}*/

/*.btn-7:hover:before {*/
/*    height: 100%;*/
/*}*/

/*.btn-7:hover:after {*/
/*    width: 100%;*/
/*}*/

.btn-7 span:before,
.btn-7 span:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: rgba(251, 75, 2, 1);
    box-shadow: -7px -7px 20px 0 rgba(255, 255, 255, .9),
    -4px -4px 5px 0 rgba(255, 255, 255, .9),
    7px 7px 20px 0 rgba(0, 0, 0, .2),
    4px 4px 5px 0 rgba(0, 0, 0, .3);
    transition: all 0.3s ease;
}

.btn-7 span:before {
    width: 2px;
    height: 0;
}

.btn-7 span:after {
    height: 2px;
    width: 0;
}

/*.btn-7 span:hover:before {*/
/*    height: 100%;*/
/*}*/

/*.btn-7 span:hover:after {*/
/*    width: 100%;*/
/*}*/

@media screen and (max-width: 940px) {
    .container {
        padding-top: 20px;
    }

    .wrapper {
        margin-bottom: 20px;
    }

    .content {
        justify-items: center;
        grid-template-columns: 1fr;
    }

    .title {
        font-size: 22px;
    }

    .input {
        width: 290px;
    }

    .new__building, .other, .instruction {
        max-width: 320px;
    }
}

@media screen and (max-width: 424px) {
    .text__wrapper {
        display: none;
    }

    .wrapper {
        grid-template-columns: 1fr;
    }
}


@media screen and (hover: hover) {
    .btn-7:hover {
        color: rgba(251, 75, 2, 1);
        background: transparent;
    }

    .btn-7:hover:before {
        height: 100%;
    }

    .btn-7:hover:after {
        width: 100%;
    }

    .btn-7 span:hover:before {
        height: 100%;
    }

    .btn-7 span:hover:after {
        width: 100%;
    }
}

@media screen and (hover: none) {

    .btn-7:active {
        color: rgba(251, 75, 2, 1);
        background: transparent;
    }

    .btn-7:active:before {
        height: 100%;
    }

    .btn-7:active:after {
        width: 100%;
    }

    .btn-7 span:active:before {
        height: 100%;
    }

    .btn-7 span:active:after {
        width: 100%;
    }
}
